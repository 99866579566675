  .grid-container {
    margin: 1% auto;
  }
  
  .grid {
    white-space: pre;
  }
  
  button {
    margin: 0px;
  } 

  