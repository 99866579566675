.footer_container {
  align-items: center;
  height: 100vh;
  background-image: url("../images/Footer.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: #fff;
  padding-left: 10vh;
  padding-right: 10vh;
}

.footer_description {
  width: 800px;
  height: fit-content;
  margin-top: 5%;
  text-align: left;
  line-height: 1.8;
  font-size: 1.5rem;
}

.footer_links_div {
  display: flex;
  width: 550px;
  height: fit-content;
  margin-top: 5%;
  justify-content: space-evenly;
  font-size: x-large;
}
.footer_links_heading {
  color: #a4dcff;
}

.footer_links {
  width: 15%;
  height: fit-content;
  line-height: 1.8;
  list-style: none;
}
.footer_link_a :hover {
  cursor: pointer;
  color: #a4dcff;
}

.footer_particle_animation {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.footer_animated_particles {
  height: 5px;
  width: 5px;
  background-color: #83ffff;
  box-shadow: 0px 0px 10px 3px #1a88d0;
  border-radius: 50%;
  animation: fly 5s linear infinite;
}

@keyframes fly {
  from {
    visibility: hidden;
  }
  to {
    transform: translateY(-200vh);
  }
}

.footer_animated_particles1 {
  animation: fly 7s linear infinite;
  transform: translateX(20vw);
}
.footer_animated_particles2 {
  transform: translateX(20vw);
  animation: fly 3s linear infinite;
}
.footer_animated_particles3 {
  animation: fly 5s linear infinite;
  transform: translateX(20vw);
}
.footer_animated_particles4 {
  animation: fly 6s linear infinite;
  transform: translateX(0vw);
}
.footer_animated_particles5 {
  animation: fly 4s linear infinite;
  transform: translateX(-20vw);
}
.footer_animated_particles6 {
  animation: fly 3s linear infinite;
  transform: translateX(-20vw);
}
.footer_animated_particles7 {
  animation: fly 3s linear infinite;
  transform: translateX(-20vw);
}
.footer_animated_particles8 {
  animation: fly 4s linear infinite;
  transform: translateX(-20vw);
}
.footer_animated_particles9 {
  animation: fly 6s linear infinite;
  transform: translateX(-20vw);
}

@media only screen and (max-width: 1080px) {
  .footer_description{
    font-size: 1rem;
  }
  .footer_links_div{
    font-size: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .footer_links_heading{
    font-size: 120%;
    /* text-align: left; */
  }
}