.sudoku-count
{
  position: relative;
  margin: 40px 0;
  padding: 14px 16px;;
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  border-radius:4px;
  display: inline-block;
  background: rgba(255,255,255,1);
}  

.sudoku-glow{
    text-shadow:(0 0 4px #ffffff)
  }