.node {
    width: 25px;
    height: 25px;
    outline: 1px solid rgb(175, 216, 248);
    display: inline-block;
  }
  
  .node-finish {
    background-color: red;
  }
  
  .node-start {
    background-color:lightblue;
  }
  
  .node-visited {
    animation-name: visitedAnimation;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }
  
  @keyframes visitedAnimation {
    0% {
      transform: scale(0.3);
      background-color: rgba(0, 0, 66, 0.75);
      border-radius: 100%;
    }
  
    50% {
      background-color: rgba(217, 17, 187, 0.75);
    }
  
    75% {
      transform: scale(1.2);
      background-color: rgba(34, 17, 217, 0.75);
    }
  
    100% {
      transform: scale(1);
      background-color:
black    }
  }
  
  .node-wall {
    background-color: rgb(12, 53, 71);
  }
  
  .node-shortest-path {
    animation-name: shortestPath;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }
  
  @keyframes shortestPath {
    0% {
      transform: scale(0.6);
      background-color: rgb(0, 0, 0);
    }
  
    50% {
      transform: scale(1.2);
      background-color:  rgb(0, 0, 0);
    }
  
   100% {
      transform: scale(1);
      background-color: green
    } 
  } 