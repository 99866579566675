.huffman-hero {
  height: 100vh;
  background-color: #000;
  display: flex;
}

.huffman-hero-left-block {
  width: 30%;
  height: 100%;
  background-color: #98c697;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.huffman-hero-left-block > img {
  width: 60%;
  /* background-color: transparent; */
}

.huffman-animation:hover {
  pointer-events: none;
}
.huffman-hero-right-block {
  width: 70%;
  height: 100%;
  background-color: #a6db8d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.huffman-hero-text-container {
  background-color: #fef9ef;
  border-radius: 5px;
  text-align: left;
  /* height: 60vh; */
  width: 80%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
}

.huffman-hero-right-para {
  margin-top: 5%;
}

.huffman-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.huffman-button-container > button {
  background-color: #ffbf86;
  outline: none;
  border: none;
  /* margin-left: 5%; */
  margin: 20px;
  border: 1px solid brown;
  border-radius: 5px;
  text-align: left;
  font-weight: bold;
  padding: 7px;
}

/* .huffman-hero-text-container > h2 {
  padding-left: 20px !important;
  padding-right: 20px !important;
} */

.huffman-upload-pic-container {
  background-color: #a6db8d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.huffman-upload-text {
  padding: 1.5rem;
  background-color: #98c697;
  border-radius: 5px;
  width: 30rem;
  font-weight: bold;
  font-size: 1.25rem;
  border: 1px solid white;
}

.huffman-show-image {
  display: flex;
  height: 100vh;
}

.huffman-image-sample {
  background-color: lightgoldenrodyellow;
  /* height: 50%;
  width: 50%; */
  border-radius: 10px;
}
