.psd{
    height: 100vh;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
}
.comb{
    text-align: center;
    height: 100vh;
    overflow: scroll;
    font-weight: bold;
    font-size: 20px;
    background-color:wheat;
}
