.box{
    width: 50px;
    height: 50px;
    border: 1px solid black;
    display: flex;
}
.box-start{
    background-color: aqua;
}
.box-end{
    background-color: brown;
}