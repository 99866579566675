.sudokuInformation {
    /* background:#faeee7 ; */
    height: 100vh;
    overflow: hidden;
}

.cards {
    position: relative;  
    list-style-type: none;
    padding: 0;
    max-width: 34em;
    margin: 0% auto;
}

.CardLists{
    list-style-type: none;
    /* text-align: center; */
}

.CardHeading{
    text-align: center;
}

.sudoku-carddata {
    position: absolute;
    top: 5rem;
    left: 0px;
    height: 80vh;
    z-index: 2;
    background:#f25f4c;
    border-radius: 30px;
    padding: 40px;
    box-shadow: 0 0 40px #000;
    transform: translateY(0) rotate(4deg) translateX(25px) scale(1);
    transform-origin: 0 0;
    transition: transform 0.6s cubic-bezier(.8,.2,.1,0.8) 0.1s , background 0.4s linear;
    cursor: pointer;
    user-select: none;
    text-align: left;
    color:#fffffe;
    font-weight: bold;
}
/* .card:last-child
{
      margin-bottom: 0;
} */
  
.card--next 
{ 
    z-index: 5; 
    transform: translateY(-25px) rotate(7deg) translateX(25px) scale(1);
}
  
.card--out 
{
    animation: card-out 0.6s cubic-bezier(.8,.2,.1,0.8);
    transform: translateY(-50px) rotate(8deg) translateX(55px) scale(0.95);
    z-index: 1;
    background:#e53170;
}
  
@keyframes card-out 
{
    0% { z-index: 20; transform: translateY(0px) rotate(-4deg); }
    50% { transform: translateY(-120%) rotate(-5deg) translateX(-40px); }
    80% { z-index: 1; }
    100% { transform: translateY(-50px) rotate(8deg) translateX(55px) scale(0.95); }
}
  
.card--current 
{
    cursor: auto;
    user-select: auto;
    position: relative;
    z-index: 10;
    opacity: 1;
    background: #ff8906;
    transform: rotate(-1deg) translateX(0%) scale(1);
}


